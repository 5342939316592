import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import WOW from "wowjs";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const RsblCalendar2025 = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
  
  <Helmet>
  <title>RSBL Calendar 2025 | Reversethought</title>
  <meta name="description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. " />
  <meta name="robots" content="max-image-preview:large" />
  <link rel="canonical" href="https://www.reversethought.com/rsbl-calendar" />
  <meta property="og:title" content="RSBL Calendar 2025 | Reversethought" />
  <meta property="og:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more"/>
  <meta property="og:url" content="https://www.reversethought.com/rsbl-calendar" />
  <meta name="twitter:title" content="RSBL Calendar 2025 | Reversethought" />
  <meta name="twitter:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more" />
    
  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            RSBL Calendar 2025
          </h1>
          <img
            src="/images/works/creative_design/rsbl2/1.png"
            alt="RsblCalendar"
            className="img-fluid wow animate__animated animate__fadeIn"
          />
          <div className="gridSystem">
            <div className="gridSystemInner">
            <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
              In an age where all physical marketing materials are readily trashed, RSBL's calendars are highly sought after and cherished for the unique perspectives and stories that they tell around gold. The creation of this calendar involves heavy research, copywriting as well as design to put together a beautiful end product   
              </p> 
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">
                  RSBL Calendar 2025
                  </span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Brand</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">Calendar</span>
                </div>
              </div>
              {/* <Link to="/" className="unsetAll" target="_blank">
                <span className="commonViewAll">Check website</span>
                <Icon icon="clarity:arrow-line" />
              </Link> */}
            </div>
            <div className="gridSystemInner">
              <div className="d-flex customGap">
                <img
                  src="/images/works/creative_design/rsbl2/2.png"
                  alt="RsblCalendar"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0">
            <div className="col-12 p-0 fullWidth wow animate__animated animate__slideInDown">
              <img
                src="/images/works/creative_design/rsbl2/3.jpg"
                alt="service1"
                className="w-100 "
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack fw-800 wow animate__animated animate__fadeIn">
            <img src="/images/icons/line.jpg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
              <a href="/ljmlogo" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/ljmlogo"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/ljm.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">LJM Logo</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/aymstall-design" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/aymstall-design"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/chair.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">AYM Stall Design</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/tatacsr-report" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/tatacsr-report"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/empower.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Tata CSR Report</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/aymleaflets" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/aymleaflets"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/scaling.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">AYM Leaflets</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
          </Slider>
          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default RsblCalendar2025;
