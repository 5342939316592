import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Icon } from "@iconify/react";
import WOW from "wowjs";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import ClientLogo from "../Shared/ClientLogo";
import News from "../Shared/News";
import Popup from "../Shared/Popup";
import WhatWeHaveDone from "../Shared/WhatWeHaveDone";
import BlogData from "./BlogData";
import { Helmet } from 'react-helmet-async';
import { Accordion } from "react-bootstrap-accordion";

const Home = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [watchReel, setWatchReel] = useState(false);
  gsap.registerPlugin(ScrollTrigger);

  const projectPreview = useRef(null);

  useEffect(() => {

    const wow = new WOW.WOW({});
    wow.init();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleCursor = () => {
    let cursor = document.querySelector(".custom-cursor");
    cursor.classList.add("active");
  };

  const watch_reel = () => {
    setWatchReel(true);
  };

  const close_reel = () => {
    setWatchReel(false);
  };



  return (
    <>
      <Helmet>
        <title>Top 10 Digital Marketing Agencies in Mumbai - Reverse Thought</title>
        <meta name="description" content="Reverse Thought is a multi-disciplinary digital creative agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. Contact Now" />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />
        <link rel="canonical" href="https://www.reversethought.com/" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Top 10 Digital Marketing Agencies in Mumbai - Reverse Thought" />
        <meta property="og:description" content="Reverse Thought is a creative digital agency based in Mumbai having more than 14 years of experience in the field of providing growth-driven solutions for websites, apps, videos, branding &amp; digital marketing" />
        <meta property="og:url" content="https://www.reversethought.com/site2023/" />
        <meta property="og:image" content="https://www.reversethought.com/images/RT-Banner-mob.jpg" />
        <meta property="og:image:secure_url" content="https://www.reversethought.com/images/RT-Banner-mob.jpg" />
        <meta property="og:image:width" content="360" />
        <meta property="og:image:height" content="480" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
        <meta name="twitter:title" content="Top 10 Digital Marketing Agencies in Mumbai - Reverse Thought" />
        <meta name="twitter:description" content="Reverse Thought is a creative digital agency based in Mumbai having more than 14 years of experience in the field of providing growth-driven solutions for websites, apps, videos, branding &amp; digital marketing" />
        <meta name="twitter:image" content="https://www.reversethought.com/images/RT-Banner-mob.jpg" />
        <meta name="google" content="nositelinkssearchbox" />

      </Helmet>
      <h1 className="d-none">Digital Creative Agency Mumbai</h1>
      <h2 className="d-none">Digital Creative Agency India</h2>
      <h2 className="d-none">Design Agency In Mumbai</h2>

      {watchReel === true ? <Popup close_reel={close_reel} /> : ""}
      <section className="homeIntro">
      <div class="iframe-container">
            <iframe style={{ "display": "block" }} className="iframe_video" width="100%" height="991" src=" https://player.vimeo.com/video/901101421?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" allow="autoplay" data-ready="true"></iframe>
        </div>

        
      </section>

      <section className="projectPreview" ref={projectPreview}>
        <div className="content_container">

        <div className="projectGalleryMain" style={{paddingBottom: "1.46412884vw"}}>
    <div className="projectsGalleryL">
        <a href="/markhome-photography2024" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className="project_box wow animate__animated animate__fadeInUp">
                <img src="/images/thumbnails/all_projects/commercial.png" alt="Project" className="img-fluid w-100" />
                <div className="project_overview">
                    <div className="top_overview">
                        <h3 className="proj_name text-black">
                            Mark Home
                            <br />
                        </h3>
                        <p className="proj_type text-black">Photography</p>
                    </div>
                    <div className="bottom_overview">
                        <a href="/markhome-photography2024" className="unsetAll" onClick={()=> window.scrollTo(0, 0)}
                            >
                            <span className="commonViewAll">View Project</span>
                            <Icon icon="clarity:arrow-line" />
                        </a>
                    </div>
                </div>
            </div>
        </a>
    </div>

    <div className="projectsGalleryM">
        <a href="/dodhiastall" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className="project_box wow animate__animated animate__fadeInUp">
                <img src="/images/thumbnails/all_projects/dodhiastall.png" alt="Project" className="img-fluid w-100" />
                <div className="project_overview">
                    <div className="top_overview">
                        <h3 className="proj_name text-black">
                            Dodhia Stall Design <br />
                        </h3>
                        <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                        <a href="/dodhiastall" className="unsetAll" onClick={()=> window.scrollTo(0, 0)}
                            >
                            <span className="commonViewAll">View Project</span>
                            <Icon icon="clarity:arrow-line" />
                        </a>
                    </div>
                </div>
            </div>
        </a>
        <a href="/dodhiaweb" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className="project_box wow animate__animated animate__fadeInUp">
                <img src="/images/thumbnails/all_projects/dodhiawebsite.png" alt="Project"
                    className="img-fluid w-100" />
                <div className="project_overview">
                    <div className="top_overview">
                        <h3 className="proj_name text-black">Dodhia Group</h3>
                        <p className="proj_type text-black">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                        <a href="/dodhiaweb" className="unsetAll" onClick={()=> window.scrollTo(0, 0)}
                            >
                            <span className="commonViewAll">View Project</span>
                            <Icon icon="clarity:arrow-line" />
                        </a>
                    </div>
                </div>
            </div>
        </a>
    </div>

    <div className="projectsGalleryR">
        <div className="projectsGalleryR">
            <a href="/konvertors" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
                <div className="project_box wow animate__animated animate__fadeInUp">
                    <img src="/images/thumbnails/all_projects/konvertorsthumb.jpg" alt="Project"
                        className="img-fluid w-100" />
                    <div className="project_overview">
                        <div className="top_overview">
                            <h3 className="proj_name text-black">
                                Konvertors Factory
                                <br />
                            </h3>
                            <p className="proj_type text-black">Photography</p>
                        </div>
                        <div className="bottom_overview">
                            <a href="/commercial" className="unsetAll" onClick={()=> window.scrollTo(0, 0)}
                                >
                                <span className="commonViewAll">View Project</span>
                                <Icon icon="clarity:arrow-line" />
                            </a>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

</div>

          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
              <a
                href="/elleyselectrics"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  {" "}
                  <img
                    src="/images/projects/all-projects/proj-1.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark text-dark">
                        Elleys’ <br />
                        Electronic
                      </h3>
                      <p className="proj_type text-dark">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/elleyselectrics"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/goodricke"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/projects/all-projects/proj-2.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">
                        Goodricke <br />
                      </h3>
                      <p className="proj_type  text-dark">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/goodricke"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>{" "}
              </a>
            </div>

            <div className="projectsGalleryM">
              <a
                href="/Memoria"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                {" "}
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/projects/all-projects/proj-5.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">Memoria</h3>
                      <p className="proj_type  text-dark">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/Memoria"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/notandas"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/projects/all-projects/proj-6.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">
                        Notandas <br />
                        Jewellery
                      </h3>
                      <p className="proj_type  text-dark">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/notandas"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/vardhman"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/projects/all-projects/proj-7.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">Vardhaman</h3>
                      <p className="proj_type  text-dark">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/vardhman"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryR">
              <a
                href="/welspunGroup"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/welp.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">Welspun </h3>
                      <p className="proj_type  text-dark">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/welspun"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/icici-pension-fund"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/nps.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">
                        ICICI Pension Fund{" "}
                      </h3>
                      <p className="proj_type  text-dark">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/icici-pension-fund"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/mcdowell"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/mcd.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h3 className="proj_name text-dark">McDowell's</h3>
                      <p className="proj_type  text-dark">
                        Digital Activations
                      </p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/mcdowell"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Projects</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <a
            href="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View All Projects</span>
            <Icon icon="clarity:arrow-line" />
          </a>
        </div>
      </section>
      <section className="whatWeDo ">
        <div className="content_container">
          <h3 className="sectionHead colorBlack fw-800 wow">
            <img src="/images/icons/line.svg" alt="line" />
            What we do
          </h3>
          <div className="row m-0 whatwedoTabs">
            <div className="col-sm-6 p-0 whatwedoTabsL">
              <ul>
                <li
                  onClick={() => handleTabClick(0)}
                  className={
                    activeTab === 0
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">01</span>
                  Websites/App
                </li>
                <li
                  onClick={() => handleTabClick(1)}
                  className={
                    activeTab === 1
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">02</span>
                  Video Production
                </li>
                <li
                  onClick={() => handleTabClick(2)}
                  className={
                    activeTab === 2
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">03</span>
                  Digital Marketing
                </li>
                <li
                  onClick={() => handleTabClick(3)}
                  className={
                    activeTab === 3
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">04</span>
                  Creative Design
                </li>
                <li
                  onClick={() => handleTabClick(4)}
                  className={
                    activeTab === 4
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">05</span>
                  Digital Activations
                </li>
                <li
                  onClick={() => handleTabClick(5)}
                  className={
                    activeTab === 5
                      ? "wow animate__animated animate__fadeInUp active"
                      : "wow animate__animated animate__fadeInUp"
                  }
                >
                  <span className="fw-800">06</span>
                  Photography
                </li>
              </ul>
            </div>
            <div className="col-sm-6 p-0 whatwedoTabsR">
              {activeTab === 0 && (
                <div
                  className={
                    activeTab === 0
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft"
                      : "whatWeDoTabsResult"
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">UI/UX</li>
                    <li className="colorBlack fw-800">Backend Development</li>
                    <li className="colorBlack fw-800">Front-end Development</li>
                    <li className="colorBlack fw-800">Mobile App design</li>
                    <li className="colorBlack fw-800">WordPress Development</li>
                  </ul>
                  <p className="colorBlack fw-400">
                    Let’s energise and elevate your website presence, with the
                    right balance of business strategy and user excitement. We
                    conceptualise, strategise, design and develop websites and
                    apps that establish new design standards in the industry,
                    while performing flawlessly on various devices. We focus
                    heavily on user experience & brand upliftment by leveraging
                    design, content and technology.
                  </p>
                  <a
                    href="/website-and-mobile/"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
              {activeTab === 1 && (
                <div
                  className={
                    activeTab === 1
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft"
                      : "whatWeDoTabsResult"
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">Corporate Profiles</li>
                    <li className="colorBlack fw-800">Corporate Profiles</li>
                    <li className="colorBlack fw-800">AVs </li>
                    <li className="colorBlack fw-800">Video Editing</li>
                    {/* <li className="colorBlack fw-800">2d Animation</li> */}
                  </ul>
                  <p className="colorBlack fw-400">
                    A video is the best marketing tool for business. We
                    conceptualise and produce a broad range of videos from
                    corporate profiles to specific application videos. Through a
                    mix of on-ground & aerial photography, graphics, animation
                    and many more styles we can produce any kind of film for any
                    application that you can think of.
                  </p>
                  <a
                    href="/video-production/"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
              {activeTab === 2 && (
                <div
                  className={
                    activeTab === 2
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft"
                      : "whatWeDoTabsResult"
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">SEO</li>
                    <li className="colorBlack fw-800">
                      Social Media Marketing
                    </li>
                    <li className="colorBlack fw-800">Performance Marketing</li>
                    <li className="colorBlack fw-800">Integrated Marketing</li>
                    {/* <li className="colorBlack fw-800">Integrated Marketing  </li> */}
                    {/* <li className="colorBlack fw-800">
                      SMS & Whatsapp Campaigns
                    </li> */}
                  </ul>
                  <p className="colorBlack fw-400">
                    If your business isn’t online and visible to your customers,
                    you’re missing out on a crucial opportunity to grow. Through
                    a range of tools such as SEO, SEM as well as social media
                    marketing we create the right strategy and content to
                    achieve your business goals both organically and through
                    influencer and paid marketing.
                  </p>
                  <a
                    href="/digital-marketing/"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
              {activeTab === 3 && (
                <div
                  className={
                    activeTab === 3
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft"
                      : "whatWeDoTabsResult"
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">Print design</li>
                    <li className="colorBlack fw-800">Branding</li>
                    <li className="colorBlack fw-800"> Creative Design</li>
                    {/* <li className="colorBlack fw-800">
                      Internal Communication
                    </li> */}
                  </ul>
                  <p className="colorBlack fw-400">
                    Creativity is the backbone of our agency and the reason for
                    us to do what we do. We design all kinds of branding
                    materials as well as collaterals that captivate and
                    effectively send the message across. We handle the design
                    and content for all kinds of digital and print creatives
                  </p>
                  <a
                    href="/Creative-Services"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
              {activeTab === 4 && (
                <div
                  className={
                    activeTab === 4
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft"
                      : "whatWeDoTabsResult"
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">AR Filters</li>
                    <li className="colorBlack fw-800">Games</li>
                    <li className="colorBlack fw-800">
                      SMS & Whatsapp Campaigns
                    </li>
                  </ul>
                  <p className="colorBlack fw-400">
                    Break the clutter and engage your audience through custom
                    developed digital activations in the form of apps, games,
                    filters and more. We work closely with your team to develop
                    concepts that add weight to your messaging and develop the
                    assets to execute your campaign.
                  </p>
                  <a
                    href="/Digital-Activation-Services"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
              {activeTab === 5 && (
                <div
                  className={
                    activeTab === 5
                      ? "whatWeDoTabsResult wow animate__animated animate__fadeInLeft fifth "
                      : "whatWeDoTabsResult "
                  }
                >
                  <ul className="d-flex">
                    <li className="colorBlack fw-800">Profile Shoot</li>
                    <li className="colorBlack fw-800">Corporate Shoot</li>
                    <li className="colorBlack fw-800">
                      Drone Assisted Photography
                    </li>
                  </ul>
                  <p className="colorBlack fw-400">
                    Capture the essence of your brand with our photography
                    service, where we bring your vision to life through expertly
                    crafted visuals that resonate with your audience. We craft
                    captivating images that not only capture moments but also
                    engage your audience, adding a unique and compelling
                    dimension to your brand.
                  </p>
                  <a
                    href="/still-photography/"
                    className="unsetAll"
                    onClick={() => window.scrollTo(0, 0)}
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    <span className="commonViewAll">Learn More</span>
                    <Icon icon="clarity:arrow-line" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="ourRecentClients">
        <div className="content_container">
          <h3 className="sectionHead colorBlack fw-800 wow">
            <img src="/images/icons/line.svg" alt="line" />
            Our Recent Clients
          </h3>
          <ClientLogo client="commonClient" />
        </div>
      </section>
      <WhatWeHaveDone />

      <section className="ourAchievements">
        <div className="content_container">
          <h3 className="sectionHead colorBlack fw-800 wow">
            <img src="/images/icons/line.svg" alt="line" />
            Our Achievements
          </h3>
          <div className="gridSystem">
            <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-3.jpg"
                alt="achievements"
                className="img-fluid"
              />
              <p className="colorBlack fw-800">Most promising Agency</p>
            </div>
            <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-4.jpg"
                alt="achievements"
                className="img-fluid"
              />
              <p className="colorBlack fw-800">Companies in Focus</p>
            </div>
            <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-5.jpg"
                alt="achievements"
                className="img-fluid"
              />
              <p className="colorBlack fw-800">Awwwards Nominee</p>
            </div>
            <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-6.jpg"
                alt="achievements"
                className="img-fluid"
              />
              <p className="colorBlack fw-800">Css Design Winner</p>
            </div>
            <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-1.jpg"
                alt="achievements"
                className="img-fluid"
                style={{ height: "25px" }}
              />
              <p className="colorBlack fw-800">Oscars of Indian Ad Awards</p>
            </div>
            {/* <div className="gridSystemInner text-center wow">
              <img
                src="/images/achievements/achievement-2.jpg"
                alt="achievements"
                className="img-fluid"
                style={{ height: "25px" }}
              />
              <p className="colorBlack fw-800">Oscars of Indian Ad Awards</p>
            </div> */}
          </div>
          <div className="creativeThoughts position-relative">
          <img
            src="/images/icons/circle-l.svg"
            alt="circle-l"
            className="wow animate__animated animate__rotateIn"
          />
            <div className="colorBlack fw-800 company">
              <p className="mb-0 wow">
                <span>Reverse Thought</span> creates
              </p>
              <p className="mb-0 wow">innovative design solutions</p>
              <p className="mb-0 wow">that inspire. Collaborate</p>
              <p className="mb-0 wow">with a digital design agency</p>
              <p className="mb-0 wow">that will make you proud.</p>
            </div>
            <div className="text-end">
            <img
              src="/images/icons/circle-r.svg"
              alt="circle-r"
              className="wow animate__animated animate__rotateIn"
            />
          </div>
          </div>
        </div>
      </section>

      <section className="blogsSection">
        <div className="content_container" >
          <h3 className="sectionHead colorBlack fw-800 wow">
            <img src="/images/icons/line.svg" alt="line" />
            Blogs
          </h3>
          <BlogData />

        </div>
      </section>

      <section className="weareintheNews">
        <div className="content_container">
          <News />
        </div>
      </section>

      <section className="aboitUsSection clientsMainSection position-relative">
        <div className="content_container">
          <div className="servicePageBanner">
            <h4 className="colorBlack fw-800 projectHeading wow animate__animated animate__fadeInUp">
              Trusted for stunning design since 2008
            </h4>
            <span class="colorBlack fw-800 hero_year wow align-items-center animated animated" >2008 <img src="/images/icons/line.svg" alt="line" /> 2024</span>
            {/* <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4">
              For the last 16 years
              <span className="colorRed fw-400 projectDesc px-2">
                we have leveraged strategy, design and technology
              </span>
              to help leading Indian and multinational organizations achieve their objectives.
            </p> */}
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4">
            For the last 16 years,
              <span className="colorRed fw-400 projectDesc px-2">
              we have leveraged strategy, design and technology 
              </span>
              to help leading Indian and multinational organizations achieve their objectives. 
              As a leading <span className="colorRed fw-400 projectDesc px-2">Digital Creative Agency in Mumbai </span>
            we can cater to your digital needs, while delivering the best solutions.
            </p>
            <p
              className="colorBlack watch_reel_txt mb-0 mt-0 wow animate__animated animate__fadeInUp animation_delay_500ms"
              onMouseEnter={props.onMouseEnterSmall}
              onMouseLeave={props.onMouseLeaveSmall}
            >
              <span
                className="colorBlack fw-500 watch_reel_txt wow text-center d-flex"
                onClick={watch_reel}
                onMouseEnter={props.onMouseEnterSmall}
                onMouseLeave={props.onMouseLeaveSmall}
              >
                <img
                  src="/images/icons/play.jpg"
                  alt="play"
                  className="img-fluid hero_reel_play "
                />
                &nbsp; Watch the Agency Reel!
              </span>
            </p>
            {/* <span className="colorBlack fw-500 reelLine">
              <img
                src="/images/icons/play.jpg"
                alt="play"
                className="img-fluid"
              />
              &nbsp; Watch the Agency Reel!
            </span> */}
          </div>
        </div>
      </section>


      <section className="aboitUsSection clientsMainSection position-relative">
        <div className="content_container">
          <div className="servicePageBanner">
            <h2 className="colorBlack fw-800 projectHeading wow animate__animated animate__fadeInUp" style={{fontSize: "50px", lineHeight: "normal"}}>
            Boost Your Brand with Reverse Thought - Top Digital Agency in Mumbai
            </h2>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4" style={{fontSize: "20px", letterSpacing: "normal"}}>Reverse Thought creates innovative design solutions that inspire. Collaborate with a <span className="colorRed fw-400 projectDesc px-2" style={{fontSize: "20px", letterSpacing: "normal"}}> digital Design Agency In Mumbai that will make you proud.</span></p>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4" style={{fontSize: "20px", letterSpacing: "normal"}}>Are you looking for the best Digital Creative Agency in India? Count on us for the best digital solutions. We offer unparalleled digital marketing services at a competitive pricing range.<span className="colorRed fw-400 projectDesc px-2" style={{fontSize: "20px", letterSpacing: "normal"}}> Our digital marketing experts will boost your brand across the globe. </span></p>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4" style={{fontSize: "20px", letterSpacing: "normal"}}>Reverse Thought is a highly recognized Design Agency In Mumbai. We offer a complete package of <span className="colorRed fw-400 projectDesc px-2" style={{fontSize: "20px", letterSpacing: "normal"}}> services including Websites/App development, Video Production, Digital Marketing, Creative Design, Digital Activations and Photography.</span> Also, we offer regular performance reports to ensure they are in the right place.</p>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4" style={{fontSize: "20px", letterSpacing: "normal"}}>We have worked with various clients successfully across India that showcases our expertise and skills. At our Digital Creative Agency in Mumbai, <span className="colorRed fw-400 projectDesc px-2" style={{fontSize: "20px", letterSpacing: "normal"}}> we involve advanced technology and plans to deliver exceptional results.</span> We offer the best online marketing services across multiple languages. Be it a start-up or an established brand, we have got you covered.</p>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms pt-4" style={{fontSize: "20px", letterSpacing: "normal"}}><span className="colorRed fw-400 projectDesc px-2" style={{fontSize: "20px", letterSpacing: "normal"}}>Contact us </span>today!</p>
          </div>
        </div>
      </section>




      {/* <section style={{backgroundColor:"black", color:"white"}}>
        <div className="content_container">             
          <div className="accordianParent wow animate__animated animate__fadeIn animation_delay_300ms">
            <Accordion title="RT">
            <p>Reverse Thought creates innovative design solutions that inspire. Collaborate with a digital Design Agency In Mumbai that will make you proud.</p>
            <p>Are you looking for the best Digital Creative Agency in India? Count on us for the best digital solutions. We offer unparalleled digital marketing services at a competitive pricing range. Our digital marketing experts will boost your brand across the globe.</p>
            <p>Reverse Thought is a highly recognized Design Agency In Mumbai. We offer a complete package of services including Websites/App development, Video Production, Digital Marketing, Creative Design, Digital Activations and Photography. Also, we offer regular performance reports to ensure they are in the right place.</p>
            <p>We have worked with various clients successfully across India that showcases our expertise and skills. At our Digital Creative Agency in Mumbai, we involve advanced technology and plans to deliver exceptional results. We offer the best online marketing services across multiple languages. Be it a start-up or an established brand, we have got you covered.</p>
            <p>Contact us today!</p>
            </Accordion>
          </div>
        </div>
      </section> */}


    </>
  );
};
export default Home;
