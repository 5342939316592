import React, { useEffect, useState } from "react";
import $ from "jquery";
import WOW from "wowjs";
import News from "../../Shared/News";
import { Helmet } from "react-helmet-async";
import { Icon } from "@iconify/react";

const DigitalMarketing = (props) => {
  const [isACtive, setisACtive] = useState(0);

  const handleTabClick = (i) => {
    setisACtive(i);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  $(".projectSectiontabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

 
  return (
    <>
    <Helmet>
        <title>Digital Marketing | Reversethought</title>
        <meta name="description" content="Reverse Thought is a multi-disciplinary Internet and Digital Marketing agency in Mumbai, India specializes in web and UI/UX design, Apps, Videos, and much more." />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="best digital marketing companies,digital marketing agencies in mumbai,digital marketing agency,digital marketing agency mumbai,digital marketing company,digital marketing firm,digital marketing services company,digital marketing services in mumbai,top 10 digital marketing companies,top digital marketing companies,top digital marketing companies in the world" />
        <link rel="canonical" href="https://www.reversethought.com/works/digital-marketing" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Digital Marketing Company in Mumbai, India" />
        <meta property="og:description" content="We are one of the top rated Digital Marketing Companies in Mumbai, India. We drive Organic traffic &amp; generate leads through Inbound Marketing strategies that reach many people." />
        <meta property="og:url" content="https://www.reversethought.com/works/digital-marketing" />
        <meta property="article:tag" content="best digital marketing companies" />
        <meta property="article:tag" content="digital marketing agencies in mumbai" />
        <meta property="article:tag" content="digital marketing agency" />
        <meta property="article:tag" content="digital marketing agency mumbai" />
        <meta property="article:tag" content="digital marketing company" />
        <meta property="article:tag" content="digital marketing firm" />
        <meta property="article:tag" content="digital marketing services company" />
        <meta property="article:tag" content="digital marketing services in mumbai" />
        <meta property="article:tag" content="top 10 digital marketing companies" />
        <meta property="article:tag" content="top digital marketing companies" />
        <meta property="article:tag" content="top digital marketing companies in the world" />
        <meta property="article:published_time" content="2018-12-26T08:38:03+00:00" />
        <meta property="article:modified_time" content="2023-12-06T11:00:37+00:00" />
        <meta property="article:publisher" content="https://www.facebook.com/rtcspl/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
        <meta name="twitter:title" content="Digital Marketing Company in Mumbai, India" />
        <meta name="twitter:description" content="We are one of the top rated Digital Marketing Companies in Mumbai, India. We drive Organic traffic &amp; generate leads through Inbound Marketing strategies that reach many people." />
        <meta name="google" content="nositelinkssearchbox" />
        
      </Helmet>
      <section className="projectsGallery">
        <div className="content_container">
          <ul className="projectSectiontabs p-0 m-0 d-flex justify-content-between align-items-center wow animate__animated animate__fadeIn">
            <a href="/works/all-projects" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1 ">All Projects</li>
            </a>
            <a href="/works/website-app" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Websites/App</li>
            </a>
            <a
              href="/works/video-production"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1">Video Production</li>
            </a>
            <a
              href="/works/digital-marketing"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1 active">
                Digital Marketing
              </li>
            </a>
            <a
              href="/works/creative-design"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Creative Design</li>
            </a>
            <a
              href="/works/digital-activation"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Digital Activations</li>
            </a>
            <a href="/works/photography" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Photography</li>
            </a>
          </ul>

          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
              <a
                href="/mpuh"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/phone.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        MPUH <br />
                      </h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/mpuh"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/advance-solution"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/49.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        Advance Solution <br />
                      </h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/advance-solution"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/icici-prudential"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/trade.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">ICICI Prudential</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/icici-prudential"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryM">
              <a
                href="/icici-pension-fund"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/nps.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        ICICI Pension Fund
                      </h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/icici-pension-fund"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/dabur"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dabur2.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        Dabur <br />
                      </h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/dabur"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/one-gaming-arcade"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/flex.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        One Gaming Arcade
                      </h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/one-gaming-arcade"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/speak-health"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/freely.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Speak Health</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/speak-health"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/sky-greens"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/skgreens.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Skygreens</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/sky-greens"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryR">
              <a
                href="/jindal-SAW"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/earth.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Jindal SAW</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/jindal-SAW"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/indusind"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/education.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">IndusInd Bank</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/indusind"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/speed-of-sound"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/ready.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Speed of Sounds</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/speed-of-sound"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/tiger"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/tigerlong.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Tiger</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/tiger"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          {/* <div className="projectGalleryMain">
          <div className="projectsGalleryL">
          <a
                href="/makoons"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dummy.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Makoons</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/makoons"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
          </div>
          <div className="projectsGalleryM">
          <a
                href="/kotak_festival"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dummy.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Kotak Festival</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/kotak_festival"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
          </div>
          <div className="projectsGalleryR">
          <a
                href="/itt_social"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dummy.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">ITT Social</h2>
                      <p className="proj_type text-black">Digital Marketing</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/itt_social"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
          </div>
          </div> */}


        </div>
      </section>
      <section className="weareintheNews customPaddingClass">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default DigitalMarketing;
